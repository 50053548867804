import React, { useState, useEffect } from "react";
import { useGame } from "../contexts/GameContext";
import { database } from "../firebase";
import Create from "./Create";
import {
  Answer,
  QHeader,
  QHeaderText,
  QNumber,
  Question,
} from "./CreateElements";

export default function CreatePage() {
  const { currentGame, currentRound } = useGame();
  const [questionList, setQuestionList] = useState();
  const roundRef = database
    .ref("games")
    .child(currentGame)
    .child("rounds")
    .child(currentRound)
    .child("questions");
  const getQuestions = () => {
    roundRef.on("value", (snapshot) => {
      const qs = snapshot.val();
      const qList = [];
      for (let id in qs) {
        qList.push({ id, ...qs[id] });
      }
      setQuestionList(qList);
    });
  };

  useEffect(() => {
      getQuestions()
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="w-100"
      style={{
        maxWidth: "80vw",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
        
      <div style={{ height: 70 }}></div>
      {questionList
        ? questionList.map((index, num) => (
            <Question key={index}>
              <QHeader>
                <QHeaderText>{index.question}</QHeaderText>
                <QNumber>{num+1}</QNumber>
              </QHeader>
              <form
                className="w-100"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    return false;
                  }
                }}
              >
                <Answer id="dr" placeholder="Song title"></Answer>
              </form>
            </Question>
          ))
        : ""}

      <Create />
    </div>
  );
}
