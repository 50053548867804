import React, { useRef, useState, useEffect } from "react";
import {
  ContainerCenter,
  JHeader,
  JHeaderText,
  IconCont,
  AnswerInput,
  QuestionInput,
  TeamCreateAdd,
  ImageStyled,
} from "./molecules/GameOverview";
import { BsInputCursorText, BsCardImage } from "react-icons/bs";
import {
  BiCheckboxSquare,
  BiMovie,
  // BiMusic,
  // BiPointer,
  BiQuestionMark,
  // BiSelectMultiple,
} from "react-icons/bi";
import { auth, storage, database } from "../firebase";
// import { useAuth } from "../contexts/AuthContext";
import { useGame } from "../contexts/GameContext";

export default function Create() {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileInput, setFileInput] = useState(false);
  const { currentGame, currentRound } = useGame();
  const ref = database
    .ref("games")
    .child(currentGame)
    .child("rounds")
    .child(currentRound)
    .child("questions");

  useEffect(() => {
    if (selectedMedia) {
      setImageUrl(URL.createObjectURL(selectedMedia));
    }
  }, [selectedMedia]);

  const questionRef = useRef();
  const mediaRef = useRef();
  const answerRef = useRef();
  const oneRef = useRef();
  const twoRef = useRef();
  const threeRef = useRef();
  const fourRef = useRef();

  const [current, setCurrent] = useState({
    questionType: "",
    answerType: "",
    question: "",
    writtenAmount: null,
    image: null,
    medialink: "",
    writtenAnswer: "",
    choiceAnswers: { 1: "", 2: "", 3: "", 4: "" },
  });

  const handleQuestionType = (props) => {
    setCurrent((current) => ({
      ...current,
      questionType: props,
    }));
  };
  const handleAnswerType = (props) => {
    setCurrent((current) => ({
      ...current,
      answerType: props,
    }));
  };
  const handleOnlyWrittenSubmit = (e) => {
    e.preventDefault();
    const insQuestion = questionRef.current.value;
    const insAnswer = answerRef.current.value;
    ref.push({
      answerType: current.answerType,
      questionType: current.questionType,
      question: insQuestion,
      answer: insAnswer,
    });
  };
  const handleImageWrittenSubmit = async (e) => {
    e.preventDefault();
    console.log(current);
    const childPath = `post/${auth.currentUser.uid}/${Math.random().toString(
      36
    )}`;
    const task = storage.ref().child(childPath).put(selectedMedia);
    const insQuestion = questionRef.current.value;
    const insAnswer = answerRef.current.value;

    const taskCompleted = () => {
      task.snapshot.ref.getDownloadURL().then((snapshot) => {
        database
          .ref("games")
          .child(currentGame)
          .child("rounds")
          .child(currentRound)
          .child("questions")
          .push({
            answerType: current.answerType,
            questionType: current.questionType,
            question: insQuestion,
            answer: insAnswer,
            srcimage: snapshot,
          })
          .then()
          .catch();
        console.log(snapshot);
      });
    };
    const taskProgress = (snapshot) => {
      console.log(`transferred: ${snapshot.bytesTransferred}`);
    };
    const taskError = (snapshot) => {
      console.log(snapshot);
    };
    task.on("state_changed", taskProgress, taskError, taskCompleted);
  };
  const handleVideoWrittenSubmit = (e) => {
    e.preventDefault();
    const insQuestion = questionRef.current.value;
    const insAnswer = answerRef.current.value;
    ref.push({
      answerType: current.answerType,
      questionType: current.questionType,
      question: insQuestion,
      answer: insAnswer,
      srcvideo: mediaRef.current.value,
    });
  };
  const handleOnlySingleSubmit = (e) => {
    e.preventDefault();
    const insQuestion = questionRef.current.value;
    const insAnswer = answerRef.current.value;
    ref.push({
      answerType: current.answerType,
      questionType: current.questionType,
      question: insQuestion,
      answer: insAnswer,
      answerOptions: {
        1: oneRef.current.value,
        2: twoRef.current.value,
        3: threeRef.current.value,
        4: fourRef.current.value,
      },
    });
  };
  const handleImageSingleSubmit = (e) => {
    e.preventDefault();
    const childPath = `post/${auth.currentUser.uid}/${Math.random().toString(
      36
    )}`;
    const task = storage.ref().child(childPath).put(selectedMedia);
    const insQuestion = questionRef.current.value;
    const insAnswer = answerRef.current.value;

    const taskCompleted = () => {
      task.snapshot.ref.getDownloadURL().then((snapshot) => {
        ref
          .push({
            answerType: current.answerType,
            questionType: current.questionType,
            question: insQuestion,
            answer: insAnswer,
            srcimage: snapshot,
            answerOptions: {
              1: oneRef.current.value,
              2: twoRef.current.value,
              3: threeRef.current.value,
              4: fourRef.current.value,
            },
          })
          .then()
          .catch();
        console.log(snapshot);
      });
    };

    const taskProgress = (snapshot) => {
      console.log(`transferred: ${snapshot.bytesTransferred}`);
    };
    const taskError = (snapshot) => {
      console.log(snapshot);
    };
    task.on("state_changed", taskProgress, taskError, taskCompleted);
  };
  const handleVideoSingleSubmit = (e) => {
    e.preventDefault();
    const insQuestion = questionRef.current.value;
    const insAnswer = answerRef.current.value;
    ref.push({
      answerType: current.answerType,
      questionType: current.questionType,
      question: insQuestion,
      answer: insAnswer,
      srcvideo: mediaRef.current.value,
      answerOptions: {
        1: oneRef.current.value,
        2: twoRef.current.value,
        3: threeRef.current.value,
        4: fourRef.current.value,
      },
    });
  };
  const SwitchQuest = (props) => {
    const { test, children } = props;
    return children.find((child) => {
      return child.props.questionType === test;
    });
  };
  const SwitchAnsw = (props) => {
    const { test, children } = props;
    return children.find((child) => {
      return child.props.answerType === test;
    });
  };
  const Case = ({ children, questionType }) => {
    return children;
  };
  return (
    <ContainerCenter>
      <JHeader>
        <JHeaderText>Create</JHeaderText>
      </JHeader>
      <h5>Question Type{currentGame}</h5>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "row",
          borderRadius: 12,
          borderWidth: 1,
          borderColor: "#000",
          borderStyle: "solid",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <IconCont
          selected={current.questionType === "only"}
          onClick={() => {
            handleQuestionType("only");
          }}
        >
          <BiQuestionMark size={26} />
          <text style={{ fontSize: 14, textAlign: "center" }}>
            Only Question
          </text>
        </IconCont>
        <IconCont
          selected={current.questionType === "image"}
          onClick={() => {
            handleQuestionType("image");
          }}
        >
          <BsCardImage size={26} />
          <text style={{ fontSize: 14 }}>Image</text>
        </IconCont>

        <IconCont
          selected={current.questionType === "video"}
          onClick={() => {
            handleQuestionType("video");
          }}
        >
          <BiMovie size={26} />
          <text style={{ fontSize: 14 }}>Video</text>
        </IconCont>
      </div>

      <h5>Answer Type</h5>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "row",
          borderRadius: 12,
          borderWidth: 1,
          borderColor: "#000",
          borderStyle: "solid",
          padding: 5,
          marginBottom: 10,
        }}
      >
        <IconCont
          selected={current.answerType === "written"}
          onClick={() => {
            handleAnswerType("written");
          }}
        >
          <BsInputCursorText size={26} />
          <text style={{ fontSize: 14 }}>Written Answer</text>
        </IconCont>
        <IconCont
          selected={current.answerType === "single"}
          onClick={() => {
            handleAnswerType("single");
          }}
        >
          <BiCheckboxSquare size={26} />
          <text style={{ fontSize: 14 }}>Single Choice</text>
        </IconCont>
      </div>
      <h5>Input Data</h5>
      <SwitchQuest test={current.questionType}>
        <Case questionType={""}>
          <div></div>
        </Case>
        {/* Only Question */}
        <Case questionType={"only"}>
          <div className="w-100">
            <text>Question</text>
            <QuestionInput
              ref={questionRef}
              placeholder="Enter Your Question"
            ></QuestionInput>
          </div>
        </Case>
        {/* Image */}
        <Case questionType={"image"}>
          <div
            className="w-100"
            style={{ flexDirection: "column", display: "flex" }}
          >
            <div>Image</div>
            <input
              type="file"
              id="select-image"
              accept="image/png, image/jpeg video/mp4 audio/mp3"
              style={{
                borderRadius: 12,
                borderWidth: 1,
                borderStyle: "solid",
                padding: 5,
                display: fileInput ? "none" : "inherit",
                marginTop: 5,
              }}
              onChange={(e) => {
                setSelectedMedia(e.target.files[0]);
                setFileInput(true);
              }}
            ></input>
            <ImageStyled alt="" src={imageUrl}></ImageStyled>
            <div>Question</div>
            <QuestionInput
              ref={questionRef}
              id="287892"
              placeholder="Enter Your Question"
            ></QuestionInput>
          </div>
        </Case>

        {/* Video */}
        <Case questionType={"video"}>
          <div
            className="w-100"
            style={{ flexDirection: "column", display: "flex" }}
          >
            <text>Question</text>
            <QuestionInput
              id="1234"
              ref={questionRef}
              placeholder="Enter Your Question"
            ></QuestionInput>
            <text>Link to Video</text>
            <QuestionInput
              id="123121"
              ref={mediaRef}
              placeholder="Enter the link to your video"
            ></QuestionInput>
          </div>
        </Case>
      </SwitchQuest>
      {/* Answer Types */}
      <SwitchAnsw test={current.answerType}>
        <Case answerType={""}>
          <div></div>
        </Case>
        {/* Written Answers */}
        <Case answerType={"written"}>
          <div className="w-100">
            <div>Answer</div>
            <AnswerInput
              ref={answerRef}
              id="23"
              placeholder="Correct Answer"
            ></AnswerInput>
          </div>
          <SwitchQuest test={current.questionType}>
            <Case questionType={""}>
              <div></div>
            </Case>
            <Case questionType={"only"}>
              <TeamCreateAdd onClick={handleOnlyWrittenSubmit}>
                oSubmit
              </TeamCreateAdd>
            </Case>
            <Case questionType={"image"}>
              <TeamCreateAdd onClick={handleImageWrittenSubmit}>
                iSubmit
              </TeamCreateAdd>
            </Case>
            <Case questionType={"video"}>
              <TeamCreateAdd onClick={handleVideoWrittenSubmit}>
                vSubmit
              </TeamCreateAdd>
            </Case>
          </SwitchQuest>
        </Case>
        <Case answerType={"single"}>
          <div style={{ alignSelf: "flex-start" }}>
            Enter possible answers (one of them should be right)
          </div>
          <AnswerInput ref={oneRef} placeholder="Answer Option 1"></AnswerInput>
          <AnswerInput ref={twoRef} placeholder="Answer Option 2"></AnswerInput>
          <AnswerInput
            ref={threeRef}
            placeholder="Answer Option 3"
          ></AnswerInput>
          <AnswerInput
            ref={fourRef}
            placeholder="Answer Option 4"
          ></AnswerInput>
          <AnswerInput
            ref={answerRef}
            placeholder="Correct Answer (1, 2, 3, or 4)"
          ></AnswerInput>

          <SwitchQuest test={current.questionType}>
            <Case questionType={""}>
              <div></div>
            </Case>
            <Case questionType={"only"}>
              <TeamCreateAdd onClick={handleOnlySingleSubmit}>
                oSubmit
              </TeamCreateAdd>
            </Case>
            <Case questionType={"image"}>
              <TeamCreateAdd onClick={handleImageSingleSubmit}>
                iSubmit
              </TeamCreateAdd>
            </Case>
            <Case questionType={"video"}>
              <TeamCreateAdd onClick={handleVideoSingleSubmit}>
                vSubmit
              </TeamCreateAdd>
            </Case>
          </SwitchQuest>
        </Case>
      </SwitchAnsw>
    </ContainerCenter>
  );
}
