import React from "react";
// import { useGame } from "../../contexts/GameContext";
import {  Nav, NavTitle, Place } from "./NavbarElements";
// import { database } from "../../firebase";

const Navbar = () => {
  // const { currentRound, setround,  } = useGame();
  // const [roundList, setRoundList] = useState();

  // const roundRef = database
  //   .ref("games")
  //   .child(currentGame)
  //   .child("rounds")
  //   .child("rounddata");
  // const getRounds = () => {
  //   roundRef.on("value", (snapshot) => {
  //     const amount = snapshot.val();
  //     const rList = [];
  //     for (let i=1; i<=amount; i++) {
  //       rList.push({num: i });
  //     }
  //     setRoundList(rList);
  //   });
  // };

  // useEffect(() => {
  //   getRounds();
  //   // eslint-disable-next-line
  // }, [roundList]);

  // if (currentRound === "") {
    return (
      <>
        <Nav>
          <NavTitle to="/">TriviaReady</NavTitle>
          {/* <NavLink to="/signup" activeStyle>
            Sign Up
          </NavLink> */}
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}

          {/* <NavBtn>
          <NavBtnLink to="/signin">Sign In</NavBtnLink>
        </NavBtn> */}
        </Nav>
        <Place />
      </>
    );
  // } else {
    // return (
    //   <>
    //     <Nav>
    //       <NavTitle
    //         to="/"
    //         onClick={() => {
    //           setround("");
    //         }}
    //       >
    //         <Exit size={26} />
    //       </NavTitle>
    //       <div>
    //         <NavTitle to="/">CurrentRound</NavTitle>
    //       </div>

    //       <NavTitle to="/rounds">
    //         <Plus size={26} />
    //       </NavTitle>
    //       {/* <NavLink to="/signup" activeStyle>
    //         Sign Up
    //       </NavLink> */}
    //       {/* Second Nav */}
    //       {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}

    //       {/* <NavBtn>
    //       <NavBtnLink to="/signin">Sign In</NavBtnLink>
    //     </NavBtn> */}
    //     </Nav>
    //     <Place />
    //   </>
    // );
  
};

export default Navbar;
