import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGame } from "../contexts/GameContext";
import { database } from "../firebase";
import { RoundSubmit } from "./CreateElements";
import {
  JHeaderText,
  JHeader,
  ContainerCenter,
  AnswerInput,
} from "./molecules/GameOverview";

export default function Rounds() {
  const [roundAmount, setRoundAmount] = useState(null);
  const navigate = useNavigate();
  const { currentGame, setround } = useGame();
  const roundRef = useRef();
  const onRoundSubmit = () => {
    if (roundRef.current.value !== "") {
      database
        .ref("games")
        .child(currentGame)
        .child("rounds")
        .child("rounddata")
        .once("value", (snapshot) => {
          setRoundAmount(snapshot.val().roundamount);
          console.log(snapshot.val().roundamount);
        })
        .then(
          database
            .ref("games")
            .child(currentGame)
            .child("rounds")
            .child(1 + roundAmount)
            .set({ round: 1 + roundAmount, roundname: roundRef.current.value }),
          database
            .ref("games")
            .child(currentGame)
            .child("rounds")
            .child("rounddata")
            .update({ roundamount: 1 + roundAmount }),
          setround(1 + roundAmount)
        );

      navigate("/create");
    }
  };
  return (
    <div
      className="w-100"
      style={{
        maxWidth: "50vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ContainerCenter>
        <JHeader>
          <JHeaderText>Create Round</JHeaderText>
        </JHeader>
        <AnswerInput
          ref={roundRef}
          placeholder="Enter Topic or Title of round"
        ></AnswerInput>
        <RoundSubmit
          onClick={() => {
            onRoundSubmit();
          }}
        >
          Create Round
        </RoundSubmit>
      </ContainerCenter>
    </div>
  );
}
