// import { FaBars } from "react-icons/fa";
import { BiHomeAlt } from "react-icons/bi";
import {FiPlusSquare} from'react-icons/fi';
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: #e0e0e0;
  position: fixed;
  height: 50px;
  display: flex;
  justify-content: space-between;
  //  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 14;
  width: 100vw;
  margin-bottom: 12px;
  box-shadow: 0px 5px 10px #d3d3d3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;
export const Place = styled.nav`
  background: #fff;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  //  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
  margin-bottom: 12px;
`;

export const NavLink = styled(Link)`
color: #000;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
border-radius: 5px;
border: 2px solid #000;
&.active {
	border: 2px solid #01BF71;
    border-radius: 5px;
}
:hover {
    color: ${({ primary }) => (primary ? "#000" : "#01BF71")};
    background: ${({ primary }) => (primary ? "#01BF71" : "#fff")};
    border-radius: 5px;
    transition: all 0.15s ease-in-out;
`;
export const NavTitle = styled(Link)`
color: #274C77;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
font-size: 20px;


:hover {
    color: ${({ primary }) => (primary ? "#000" : "#E7D2CC")};
    
   
    transition: all 0.15s ease-in-out;
`;
export const Exit = styled(BiHomeAlt)`
  color: #2a547e;
`;
export const Plus = styled(FiPlusSquare)`
  color: #2a547e;
`;

// export const Bars = styled(FaBars)`
//   display: none;
//   color: #808080;
//   @media screen and (max-width: 786px) {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   }
// `;

export const NavMenu = styled.div`
  display: inherit;

  align-items: center;
  margin-right: 0px;
  white-space: nowrap;
  */ @media screen and (max-width: 768px) {
    display: none;
  }
`;
