import { Link } from "react-router-dom";
import styled from "styled-components";

export const GameOverview = styled(Link)`
  border-radius: 5px;
  background: ${({ primary }) => (primary ? "#eee" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#647C90")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: 2px solid #647c90;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  margin-bottom: 10px;

  &:hover {
    transition: all 0.15s ease-in-out;
    background: ${({ primary }) => (primary ? "#647C90" : "#01BF71")};
    color: ${({ primary }) => (primary ? "#eee" : "#eee")};
    border: 2px solid #647c90;
    border-radius: 5px;
  }
`;
export const SubmitButton = styled(Link)`
  border-radius: 5px;
  white-space: nowrap;
  padding: 6px 48px;
  background: #647c90;
  color: #eee;
  font-size: 16px;
  outline: none;
  border: 2px solid #647c90;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  margin-bottom: 10px;
  width: 40%;
  margin-top: 10px;

  &:hover {
    transition: all 0.15s ease-in-out;
    background: #2a547e;
    color: #eee;
    border: 2px solid #2a547e;
    border-radius: 5px;
    text-decoration: none;
  }
`;

export const Code = styled.input`
  background: #eee;
  border-radius: 5px;
  border: 1px solid #647c90;
  width: 70%;
  padding: 6px;
  font-size: 24px;
  margin-top: 3px;
  text-align: center;
  text-transform: ${({ cases }) => (cases ? "uppercase" : "")};
`;
export const ContainerCenter = styled.div`
  background: #e0e0e0;
  border-width: 0px;
  border-radius: 16px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  margin-bottom: 60px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const JHeaderText = styled.h3`
  padding-left: 5;
  text: center;
  margin-bottom: 16px;
`;
export const JHeader = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TeamContainer = styled.div`
  background: #eeeeee;
  border: 2px solid #647c90;
  box-sizing: border-box;
  border-radius: 0px 0px 12px 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const TeamName = styled.text`
  font-size: 18px;
  color: #eee;
  text-align: center;
`;
export const TeamCreateName = styled.input`
  font-size: 18px;
  color: #242424;
  text-align: center;
  background: #eee;
  width: 100%;
  border: 2px solid #242424;
  border-radius: 12px 12px 0px 0px;
`;
export const TeamHeader = styled.div`
  background: #2a547e;
  border-radius: 12px 12px 0px 0px;
  padding: 5px 10px 5px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 3;
`;
export const TeamCreateHeader = styled.div`
  background: #eee;
  border-radius: 12px 12px 0px 0px;
  padding: 5px 0px 5px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 3;
  display: flex;
`;

export const NameContainer = styled.div`
  background: #eeeeee;
  box-sizing: border-box;
  border-radius: 0px 0px 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
`;
export const NameTeams = styled.text`
  font-size: 14px;
  color: #000;
  text: center;
`;
export const TeamAddName = styled.text`
  font-size: 14px;
  color: #000;
  text: center;
  font-weight: 700;
`;

export const TeamCreateAdd = styled.button`
  background: #2a547e;
  border: 2px solid #2a547e;
  box-sizing: border-box;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  transition: 0.2s all ease-in-out;
  font-size: 14px;
  color: #eee;
  text: center;
  font-weight: 700;
  &:hover {
    background: #eee;
    border: 2px solid #2a547e;
    transition: 0.2s all ease-in-out;
    color: #2a547e;
  }
`;

export const TeamSpacer = styled.div`
  background: #647c90;
  height: 1px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const IconCont = styled.button`
  color: ${({ selected }) => (selected ? "#eee" : "#2a547e")};
  background: ${({ selected }) => (selected ? "#2a547e" : "#e0e0e0")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  min-width: 23%;
  border-radius: 12px;
  padding: 4px;
  border: 2px solid #e0e0e0;
  transition: 0.2s all ease-in-out;
  cursor: pointer;

  &:hover {
    transition: 0.2s all ease-in-out;
    border: 2px solid #2a547e;
  }
`;

export const AnswerInput = styled.input`
  background: #eee;
  border-radius: 5px;
  border: 1px solid #647c90;
  width: 100%;
  padding: 0px;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;

`;
export const QuestionInput = styled.input`
  background: #eee;
  border-radius: 5px;
  border: 1px solid #647c90;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;

`;

export const ImageStyled = styled.img`
  width: 80%;
  max-width: 60vw;
  border: 2px solid #647c90;
  border-radius: 12px;
  padding: 0;
  align-self: center;
  margin: 5px;
`;
