import React, { useState, useEffect } from "react";
import { Button, Card, Alert } from "react-bootstrap";

import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import {
  Question,
  QHeader,
  QHeaderText,
  SelectType,
} from "./CreateElements";
import { GameOverview } from "./molecules/GameOverview";
import { useGame } from "../contexts/GameContext";
import { database } from "../firebase";

export default function Dashboard() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState();
  useEffect(() => {
// eslint-disable-next-line
    database.ref("users/" + currentUser.uid).once("value", (snapshot) => {
      setUserName(snapshot.val().name);
      
    });
  }, [userName]);

  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const { creategame } = useGame();
  async function handleLogOut() {
    setError("");

    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <div style={{ flexDirection: "column" }}>
      <div style={{ height: 70 }}></div>
      <div
        className="w-100"
        style={{
          maxWidth: "100vw",
          minWidth: "90vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Question>
          <QHeader middle="true">
            <QHeaderText middle="true">Hi, {userName} </QHeaderText>
          </QHeader>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SelectType primary="true" to="/rounds" onClick={creategame}>
              Create
            </SelectType>
            <SelectType primary="true" to="/join">
              Join
            </SelectType>
          </div>
        </Question>
        <Card
          style={{
            backgroundColor: "#e0e0e0",
            borderWidth: 0,
            borderRadius: 16,
            boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
            marginBottom: 60,
          }}
        >
          <Card.Body>
            <h2 className="text-center mb-4">Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <div>
              <strong style={{ width: "10%" }}>Email: </strong>
              <div style={{ width: "90%", float: "right" }}>
                {currentUser.email}
              </div>
            </div>

            <Link
              to="/login"
              className="btn w-100 mt-3"
              style={{ backgroundColor: "#E7D2CC" }}
            >
              Update Profile
            </Link>
          </Card.Body>
          <div className="w-100 text-center mt-2">
            <Button
              variant="link"
              onClick={() => {
                handleLogOut();
              }}
            >
              Log Out
            </Button>
          </div>
        </Card>
        <div className="w-100 text-center mt-2">
          <GameOverview to="/create" primary="true">
            Complete
          </GameOverview>
        </div>
      </div>
    </div>
  );
}
