import React, { useContext, useEffect, useState } from "react";
import { auth, database } from "../firebase";

const GameContext = React.createContext(null);

export const useGame = () => {
  return useContext(GameContext);
};

export function GameProvider({ children }) {
  const [currentGame, setCurrenGame] = useState();
  const [loading, setLoading] = useState(true);
  const [currentRound, setCurrentRound] = useState(null);

  function creategame() {
    const rand = Math.floor(100000 + Math.random() * 100000);
    database
      .ref("games")
      .child(rand.toString())
      .set({ gameid: rand, owner: auth.currentUser.uid });
    database
      .ref("games")
      .child(rand.toString())
      .child("rounds").child('rounddata')
      .set({ roundamount: 0});

    setCurrenGame(rand.toString());
  }
  function setround(prop){
    setCurrentRound(prop)
  }


  useEffect(() => {
    setLoading(false);
  }, []);

  const value = {
    creategame,
    currentGame,
    currentRound,
    setround,

  };

  return (
    <GameContext.Provider value={value}>
      {!loading && children}
    </GameContext.Provider>
  );
}
