import React from "react";
import { SubmitButton, Code, ContainerCenter, JHeaderText, JHeader } from "./molecules/GameOverview";

export default function Join() {
  return (
    <div
      className="w-100"
      style={{
        maxWidth: '50vw',
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ContainerCenter>
        <JHeader middle="true" >
          <JHeaderText>Are you TriviaReady?</JHeaderText>
        </JHeader>
        <text style={{fontSize: 14, color: '#647C90', textAlign: 'center'}}>Please enter tag</text>
        <Code placeholder="Abc123" ></Code>
        <text style={{fontSize: 12, color: '#637b8e', textAlign: 'center'}}>(Your organizer should be displaying it now)</text>
        <SubmitButton to="/name">
          Submit
        </SubmitButton>
      </ContainerCenter>
    </div>
  );
}
