import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";


const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIRBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIRBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRBASE_APP_ID,
  // databaseURL: process.env.REACT_APP_FIRBASE_DATABASE_URL,
});

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const database = app.database('https://auth-dev-9f32a-default-rtdb.europe-west1.firebasedatabase.app/');

export default app;
