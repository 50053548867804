import React, { useRef } from "react";
import {
  SubmitButton,
  Code,
  ContainerCenter,
  JHeaderText,
  JHeader,
} from "./molecules/GameOverview";
import { FaCheck } from "react-icons/fa";
import { database } from "../firebase";
import { useAuth } from "../contexts/AuthContext";

export default function Name() {
  const nameRef = useRef();
  const { currentUser } = useAuth();

  const handleName = () => {
    database.ref("users/" + currentUser.uid).set({ user: "blue" });
  };

  return (
    <div
      className="w-100"
      style={{
        maxWidth: "50vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ContainerCenter>
        <JHeader>
          <JHeaderText>What should we call you?</JHeaderText>
        </JHeader>
        <text style={{ fontSize: 14, color: "#647C90" }}>
          Please enter your name
        </text>
        <Code placeholder="eg. Alex" ref={nameRef} case="false"></Code>
        <SubmitButton onClick={() => handleName()} to="/teamselect">
          <FaCheck />
        </SubmitButton>
      </ContainerCenter>
    </div>
  );
}
