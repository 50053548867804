import React from "react";
import { AuthProvider } from "../contexts/AuthContext";
import Signup from "./Signup";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
// import Create from "./Create";
import Navbar from "./Navbar";
import Teams from "./Teams";
import Join from "./Join";
import Name from "./Name";
import Standings from "./Standings";
import { GameProvider } from "../contexts/GameContext";
import Rounds from "./Rounds";
import CreatePage from "./CreatePage";
import Game from "./Game";

function App() {
  return (
    <div
      style={{
        backgroundColor: "#eeeeee",
        maxWidth: "120vw",
        minWidth: "98vw",
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
      }}
    >
      <AuthProvider>
        <GameProvider>
          <BrowserRouter basename="/">
            <Navbar />

            <div
              style={{
                display: "flex",
                maxWidth: "80vw",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "60vw",
              }}
            >
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/create"
                  element={
                    <PrivateRoute>
                      <CreatePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/rounds"
                  element={
                    <PrivateRoute>
                      <Rounds />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/teamselect"
                  element={
                    <PrivateRoute>
                      <Teams />
                    </PrivateRoute>
                  }
                />
                <Route path='/game' element={<Game/>} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/join" element={<Join />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/name" element={<Name />} />
                <Route path="/standings" element={<Standings />} />
              </Routes>
            </div>
          </BrowserRouter>
        </GameProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
