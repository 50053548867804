import React from "react";
import {
  ContainerCenter,
  JHeaderText,
  JHeader,
} from "./molecules/GameOverview";

export default function Standings() {
  return (
    <div
      className="w-100"
      style={{
        maxWidth: "50vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ContainerCenter>
        <JHeader>
          <JHeaderText>Standings</JHeaderText>
        </JHeader>
      </ContainerCenter>
    </div>
  );
}
