import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as RouteLink } from "react-router-dom";


export const Position = styled(Link)`
  border-radius: 100px;
  background: #868B8E;
  z-index: 15;
  width: 5vw;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #000
  margin: 5px;
  text-decoration: none;
  color: #fff;
  
`;

export const Question = styled.div`
  background: #e0e0e0;
  border-width: 0px;
  border-radius: 16px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  margin-bottom: 60px;
  padding: 20px;
`;

export const QHeader = styled.div`
  justify-content:  ${({ middle }) => (middle ? "center" : "space-between")};
  display: flex;
`;
export const QHeaderText = styled.h3`
  padding-left: 5;
  max-width: 80vw;
  text: start;
  margin-bottom: ${({ middle }) => (middle ? "16px" : "10px")};
`;

export const QNumber = styled.div`
  height: 4vh;
  width: 4vh;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 3px solid #647c90;
  border-radius: 100px;
  color: #fff;
  background: #647c90;
  font-weight: bold;
`;
export const Answer = styled.input`
  background: #eee;
  border-radius: 5px;
  border: 1px solid #647C90;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 3px;
  text-align: center
`;

export const Thanos = styled.button`
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 700;
  font-size: 17px;
  /* -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
     box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);*/
  transition: all 250ms;
  overflow: hidden;
  margin: 20px;
  width: 40vw;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 15px;
    background-color: #212121;
    z-index: -1;
    /*-webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
     box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);*/
    transition: all 250ms;
  }

  &:hover {
    color: #e8e8e8;
  }

  &:hover::before {
    width: 100%;
  }
`;


export const SelectType = styled(RouteLink)`
  border-radius: 5px;
  background: ${({ primary }) => (primary ? "#eee" : "#010606")};
  white-space: nowrap;
  padding:  12px 30px;
  color: ${({ dark }) => (dark ? "#010606" : "#647C90")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: 2px solid #647C90;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  text-decoration: none;

  width: 49%;
  height: 50px
  flex-direction: column;


  &:hover {
    transition: all 0.15s ease-in-out;
    background: ${({ primary }) => (primary ? "#647C90" : "#01BF71")};
    color: ${({ primary }) => (primary ? "#eee" : "#eee")};
    border: 2px solid #647C90;
    border-radius: 5px;
    transform: translateY(-1px);
  }
`;

export const RoundSubmit = styled.button`
  background: #2a547e;
  border: 2px solid #2a547e;
  box-sizing: border-box;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  transition: 0.2s all ease-in-out;
  font-size: 14px;
  color: #eee;
  text: center;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    background: #eee;
    border: 2px solid #2a547e;
    transition: 0.2s all ease-in-out;
    color: #2a547e;
  }
`;