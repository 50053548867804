import React from "react";
import {
  ContainerCenter,
  JHeaderText,
  JHeader,
  TeamContainer,
  TeamHeader,
  TeamName,
  NameContainer,
  NameTeams,
  TeamCreateAdd,
  TeamSpacer,
  TeamCreateName,
} from "./molecules/GameOverview";

export default function Teams() {
  return (
    <div
      className="w-100"
      style={{
        minWidth: "70vw",
        maxWidth: "90vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ height: 70 }}></div>
      <ContainerCenter>
        <JHeader>
          <JHeaderText>Teams</JHeaderText>
        </JHeader>

        <div
          className="w-100"
          style={{
            display: "flex",
            maxWidth: "80vw",
            alignItems: "flex-start",
            justifyContent: "center",
            flexWrap: "wrap",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "20vw", minWidth: 200, margin: 10 }}>
            <TeamCreateName placeholder="Team Name"></TeamCreateName>

            <TeamCreateAdd>Create Team</TeamCreateAdd>
          </div>
          <div style={{ width: "20vw", minWidth: 200, margin: 10 }}>
            <TeamHeader>
              <TeamName>Michael Smith</TeamName>
            </TeamHeader>
            <TeamContainer>
              <NameContainer>
                <NameTeams>Maximilian</NameTeams>
              </NameContainer>
              <TeamSpacer></TeamSpacer>
              <NameContainer>
                <NameTeams>Diana</NameTeams>
              </NameContainer>
            </TeamContainer>
            <TeamCreateAdd>Join</TeamCreateAdd>
          </div>
        </div>
      </ContainerCenter>
    </div>
  );
}
